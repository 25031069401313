<template>
  <div class="OrderDetails">
    <el-page-header @back="back" content="订单详情 "></el-page-header>
    <div class="f-w-b">
      <NavigationBar></NavigationBar>
      <div class="main">
        <div class="banner">
          {{ enterpriseStateInfo.enterpriseName }}
        </div>
        <div class="f-c-e">
          <div class="printBtn" @click="handlePrintBtn"></div>
        </div>
        <div class="boxShadow infoTable">
          <div>
            <div class="title">订单信息</div>
            <div>
              <div v-for="(item, index0) of OrderInfoList" :key="index0 + 1">
                <div v-if="item.value" class="f-c-b titleItem">
                  <span class="titval">{{ item.title }}</span>
                  <div style="width: 50%" v-if="item.title == '退货记录' && item.value == 'Y'">
                    <div @click="checkReturnRecord"
                      style="height: 40px;width: 80px; background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);border-radius: 10px;color: #FFF;line-height: 40px;text-align: center;cursor: pointer;">
                      查看</div>

                  </div>
                  <span class="value" v-else>{{ item.value }}</span>
                </div>
              </div>
            </div>

            <div class="title mt-30">客户信息</div>
            <div>
              <div v-for="(item1, index1) of CostomerInfoList" :key="index1">
                <div v-if="item1.value" class="f-c-b titleItem">
                  <span class="titval">{{ item1.title }}</span>
                  <span class="value">{{ item1.value }}</span>
                </div>
              </div>
            </div>

            <div class="title mt-30">商品明细</div>
            <div>
              <div class="f-w-b h-60 talTit">
                <span>品名</span>
                <span>编号</span>
                <span>价格</span>
                <span>数量</span>
                <span>小计</span>
                <span>备注</span>
              </div>
              <div v-if="orderDetailInfo.orderDetailsList">
                <div class="talVal f-w-b" v-for="(item2, index2) of orderDetailInfo.orderDetailsList" :key="index2 + 2">
                  <span class="f-c-c">{{ item2.customerUseName }}</span>
                  <span class="f-c-c">{{ item2.customerUseNumber ? item2.customerUseNumber : '-' }}</span>
                  <span class="f-c-c">{{ item2.unitPrice }}</span>
                  <span class="f-c-c">{{ item2.quantity }}</span>
                  <span class="f-c-c" :style="{ 'color': item2.amount2 == '已退货' ? 'red' : '#333' }">{{ item2.amount2
                  }}</span>
                  <div>{{ item2.note ? item2.note : '-' }}</div>
                </div>
              </div>
              <div v-else class="talVal2 f-c-c">
                <span>暂无数据</span>
              </div>

            </div>
            <div class="title mt-30">订单备注</div>
            <div class="modifyNote">
              {{ orderDetailInfo.orderNote ? orderDetailInfo.orderNote : '暂无数据' }}
            </div>
            <div class="title mt-30">操作备注</div>
            <div class="modifyNote">
              {{ orderDetailInfo.modifyNote ? orderDetailInfo.modifyNote : '暂无数据' }}
            </div>
          </div>
        </div>
        <!-- 未发货 -->
        <div class="f-c-b mt-30" v-if="orderDetailInfo.orderStatus == 'Ready' && o_m == 'W'">
          <div @click="modifyOrder" class="btn">修改订单</div>
          <div @click="scrappedOrderActive" class="btn">取消订单</div>
          <div @click="reviseOrderActiveState" class="btn">确认发货</div>
          <div @click="reviseOrderActiveStateCollection(1)" class="btn">一键结算</div>
        </div>
        <!-- 已发货 -->
        <div class="f-c-a Two-btn" v-else-if="orderDetailInfo.orderStatus == 'Deliver' && o_m == 'W'">
          <div @click="ReturnGood" class="btn"
            v-if="orderDetailInfo.isShowReturnGoodBtn || orderDetailInfo.realityAmount > 0">退货</div>
          <div @click="reviseOrderActiveState" class="btn">确认客户收货</div>
          <div @click="reviseOrderActiveStateCollection(1)" class="btn">一键结算</div>
        </div>
        <!--  -->
        <div class="f-c-a"
          :class="(orderDetailInfo.isShowReturnGoodBtn || orderDetailInfo.realityAmount > 0) ? 'Two-btn' : 'One-btn'"
          v-else-if="orderDetailInfo.orderStatus == 'Delivery' && o_m == 'W'">
          <div v-if="orderDetailInfo.isShowReturnGoodBtn || orderDetailInfo.realityAmount > 0" @click="ReturnGood"
            class="btn">退货</div>
          <div @click="reviseOrderActiveStateCollection(2)" class="btn">确认客户收款</div>
        </div>
        <!-- 已完成 -->
        <div class="One-btn f-c-c"
          v-else-if="((orderDetailInfo.orderStatus == 'Completed' && orderDetailInfo.realityAmount > 0) || (orderDetailInfo.orderStatus == 'Completed' && orderDetailInfo.isShowReturnGoodBtn)) && o_m == 'W'">
          <div @click="ReturnGood" class="btn">退货</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { TableColumn1 } from '@/utils/TableColumn'
export default {
  name: 'OrderDetails',
  components: {
    NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
  },
  inject: ['reload'],
  data() {
    return {
      OrderInfoList: [],//订单信息
      CostomerInfoList: [],//客户信息
      orderDetailInfo: {},
      TableColumn: TableColumn1,
      orderStatus: '',//请求类型
      queryId: '',//请求id
      o_m: '', //权限
      enterpriseStateInfo: {}
    }
  },
  created() {
    let params = JSON.parse(this.$UrlEncode.decode(this.$route.query.key))
    this.orderStatus = params.orderStatus;
    this.queryId = params.id
    this.findAllOrderDetails();
    this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
    let { o_m } = this.$getStorage('enterpriseStateInfo').userPermission;
    this.o_m = o_m;
  },
  watch: {
    '$route.path': {
      handler(newVal) {
        let pageLayer = this.$getStorage('pageLayer') ? this.$getStorage('pageLayer') : 0,
          pageLayerBack = this.$getStorage('pageLayerBack') ? this.$getStorage('pageLayerBack') : 0;
        if (pageLayer.length == 1) {
          pageLayer.push(newVal)
          this.$setStorage('pageLayer', pageLayer)
        }
        if (pageLayerBack.length == 1) {
          pageLayerBack.push(newVal)
          this.$setStorage('pageLayerBack', pageLayerBack)
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  methods: {
    back() {
      let pageLayer = this.$getStorage('pageLayer');
      pageLayer && pageLayer.length > 0 ? this.$setStorage('pageLayer', pageLayer.slice(0, -1)) : pageLayer
      let pageLayerBack = this.$getStorage('pageLayerBack');
      pageLayerBack && pageLayerBack.length > 0 ? this.$setStorage('pageLayerBack', pageLayerBack.slice(0, -1)) : pageLayerBack
      this.$common.DelayedBack(0);
    },
    // 打印页
    handlePrintBtn() {
      this.$setStorage('PrintParam', {
        orderId: this.queryId,
        orderStatus: this.orderStatus,
        templateParam: 'SA'
      })
      this.$router.push('/PrintPage')
    },
    // 用户搜索查看全部订单列表详细信息接口
    // returnFlag字段是用于 是否显示退货记录按钮的
    // deleteFlag R-代表商品已退货 
    findAllOrderDetails() {
      let that = this;
      that.$http.findAllOrderDetails({
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        orderId: that.queryId,
        orderTypeStatus: that.orderStatus
      }).then((res) => {
        let { orderNumber, orderStatus, valuationCurrency, realityAmount2, freightCurrency, freightAmount,
          createName, createDate, returnFlag, customerName, contact, contactPhone, customerCity, customerAddress,
          deliverDate, deliveryDate, lastModifyDate } = res.data,
          orderStatusText = that.$orderClass.getOrderState(orderStatus),
          address = customerCity + ' ' + customerAddress;
        that.OrderInfoList = [{
          "title": "订单编号",
          "value": orderNumber
        }, {
          "title": "订单状态",
          "value": orderStatusText
        }, {
          "title": "成交金额",
          "value": valuationCurrency + ' ' + realityAmount2
        }, {
          "title": "运费",
          "value": freightCurrency + ' ' + (freightAmount ? freightAmount.toFixed(2) : freightAmount)
        }, {
          "title": "创建人",
          "value": createName
        }, {
          "title": "创建时间",
          "value": createDate
        }, {
          "title": "发货时间",
          "value": deliverDate
        }, {
          "title": "收货时间",
          "value": deliveryDate
        }, {
          "title": "更新时间",
          "value": lastModifyDate
        }, {
          "title": "退货记录",
          "value": returnFlag == 'N' ? '无' : returnFlag
        }];
        that.CostomerInfoList = [{
          "title": "客户名称",
          "value": customerName
        }, {
          "title": "联系人",
          "value": contact
        }, {
          "title": "联系电话",
          "value": contactPhone
        }, {
          "title": "地址",
          "value": address
        }];
        res.data.orderDetailsList.forEach((item) => {
          item.inventoryQuantity = ''
          if (item.deleteFlag == "R") {
            item.amount2 = '已退货'
          }
        })
        let noReturnGoodList = res.data.orderDetailsList.filter(item => {
          return item.deleteFlag == "N"
        })
        noReturnGoodList.length > 0 ? res.data.isShowReturnGoodBtn = true : res.data.isShowReturnGoodBtn = false;
        that.orderDetailInfo = res.data;
      })
    },
    // 取消订单
    scrappedOrderActive() {
      let that = this;
      that.$confirm('是否取消该订单？', '提示').then(() => {
        that.$http.scrappedOrderActive({
          lastModifyUserId: parseInt(that.$store.state.userId),
          token: that.$getStorage('token'),
          orderId: that.orderDetailInfo.orderId
        }).then((res) => {
          if (res.code == 200) {
            that.$common.message(res.msg, 'success')
            that.$common.DelayedBack();
          }
        })
      }).catch(() => { })
    },
    // 订单状态改变 确认发货/确认客户收货
    reviseOrderActiveState() {
      let that = this,
        ParamsOrderStatus = '';
      if (that.orderDetailInfo.orderStatus == 'Ready') {
        ParamsOrderStatus = 'Deliver'
      } else if (that.orderDetailInfo.orderStatus == 'Deliver') {
        ParamsOrderStatus = 'Delivery'
      }
      that.$confirm('是否已发货？', '提示').then(() => {
        that.$http.reviseOrderActiveState({
          lastModifyUserId: parseInt(that.$store.state.userId),
          token: that.$getStorage('token'),
          orderId: that.orderDetailInfo.orderId,
          lastModifyDate: that.orderDetailInfo.lastModifyDate,
          orderStatus: ParamsOrderStatus //确认发货--Deliver（运输中）、确认收货--Delivery（客户收货未付款）
        }).then((res) => {
          if (res.code == 200) {
            that.$common.message(res.msg, 'success')
            that.$common.DelayedBack();
          }
        })
      }).catch(() => { })
    },
    // 确认客户收款
    reviseOrderActiveStateCollection(type) {
      let that = this;
      that.$confirm(`是否${type == 1 ? '确定一键结算？' : '已付款？'}`, '提示').then(() => {
        that.$http.reviseOrderActiveStateCollection({
          lastModifyUserId: parseInt(that.$store.state.userId),
          token: that.$getStorage('token'),
          lastModifyDate: that.orderDetailInfo.lastModifyDate,
          orderId: that.orderDetailInfo.orderId
        }).then((res) => {
          if (res.code == 200) {
            that.$common.message(res.msg, 'success')
            that.$common.DelayedBack();
          }
        })
      }).catch(() => { })
    },
    // 跳转退货页
    ReturnGood() {
      let orginData = this.orderDetailInfo,
        orginProductList = orginData.orderDetailsList,
        NoReturnProductList = orginProductList.filter(item => {
          return item.deleteFlag == "N"
        });
      NoReturnProductList.forEach(item => {
        if (item.productNumber == '') {
          item.productNumber = '无'
        }
      })
      orginData.orderDetailsList = NoReturnProductList;
      // 订单明细作为参数带去退货单使用
      this.$setStorage('OrderDetailsUseReturnDoc', orginData);
      this.$router.push('/AddReturnDoc')
    },
    // 查看退货记录-》跳转到关联订单页
    checkReturnRecord() {
      this.$router.push({
        path: '/AssociatedOrder',
        query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            id: this.orderDetailInfo.orderId
          }))
        }
      })
    },
    // 订单修改
    modifyOrder() {
      this.$router.push('/UnshippedOrderModification')
      this.$setStorage('OrderDetailNeepModify', this.orderDetailInfo)
    }
  },
}

</script>
<style lang='less' scoped>
.OrderDetails {
  .main {
    width: 980px;

    .banner {
      background: url('../../../assets/supply/supplyBanner.png');
      height: 100px;
      color: #FFFFFF;
      font-size: 30px;
      line-height: 100px;
      text-align: center;
      margin-bottom: 40px;
    }

    .printBtn {
      width: 120px;
      height: 50px;
      background: url('../../../assets/supply/print.png');
      cursor: pointer;
      margin-bottom: 20px;

    }

    .infoTable {
      width: 899px;
      padding: 40px;
      border-radius: 10px;

      .title {
        margin-bottom: 22px;
        border-left: 4px solid #0363FA;
        padding-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
      }

      .titleItem {
        padding: 10px 30px;
        width: 840px;
        // height: 50px;
        min-height: 30px;
        border: 1px solid #f2f2f2;
        margin-bottom: 5px;

        .titval {
          font-size: 16px;
          color: #999;
          font-weight: 400;
          width: 50%;
        }

        .value {
          line-height: 20px;
          font-size: 16px;
          color: #333;
          font-weight: 400;
          width: 50%;
        }
      }

      .talTit {
        border-top: 1px solid #EDECEC;
        border-left: 1px solid #EDECEC;
        background: #F8F8F8;
        // border-radius: 5px 5px 0px 0;

        span {
          width: calc(100% / 6 - 6px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 500;
          font-size: 18px;
          border-right: 1px solid #EDECEC;
        }
      }

      .talVal {
        background: #fff;
        border-left: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;

        span {
          width: calc(100% / 6 - 6px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
        }

        div {
          width: calc(100% / 6 - 6px);
          text-align: center;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 25px;
        }
      }

      .talVal2 {
        background: #fff;
        border-left: 1px solid #EDEDED;
        border-right: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;
        height: 60px;
      }

      .modifyNote {
        padding: 30px;
        // height: 230px;
        background: #F3F8FE;
        border-radius: 5px;
        color: #666666;
        white-space: pre-line;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .btn {
      width: 160px;
      height: 50px;
      background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
      border-radius: 10px;
      color: #FFF;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }





  .printBtn {
    span {
      margin-left: 5px;
    }
  }

  .printBtn:hover {
    cursor: pointer;
  }
}
</style>
